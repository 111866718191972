/**
   Active Styling for PrimeNG Components, and inputs
   Note that this classes was added to generalize this styling
 */
.p-focus:not(.p-disabled),
input:focus,
input:hover,
.p-dropdown:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled):hover {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #4c4d4c !important;
}

/**
  Dropdown and multi select styling.
  Note that this styles was added to handle the dropdowns appended to the body,
 */
.p-dropdown-header,
.p-multiselect-header {
  background: #ffffff !important;
  padding: 10px 15px !important;
  .p-dropdown-filter.p-inputtext,
  .p-multiselect-filter.p-inputtext {
    border: 0px !important;
    padding: 5px;
    font-size: 15px;
  }
  .p-dropdown-filter.p-inputtext:focus,
  .p-multiselect-filter.p-inputtext:focus {
    outline: none !important;
    box-shadow: none;
  }
}
.p-multiselect-header {
  padding: 10px 20px !important;
}
.p-dropdown-panel,
.p-multiselect-panel {
  box-shadow: 0 1px 10px #00000040 !important;
}
.p-dropdown-items,
.p-multiselect-items {
  font-size: 15px !important;
}
.p-multiselect-label-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 44px;
}
/**
  Datepicker styling.
  Note that this styles was added to handle the datepicker appended to the body,
  assuming that there will not be a time picker in it
 */
.p-datepicker {
  width: 400px !important;
  height: 360px !important;
  .p-datepicker-other-month {
    visibility: hidden !important;
  }
}

body .p-datepicker table td {
  padding: 0.2rem;
}

.lead-dropdown {
  height: 35px;
  min-width: 150px;
  .p-dropdown {
    min-height: initial !important; // switch container height
    height: 35px;
  }
  .p-dropdown-label {
    padding: 0 0 0 10px;
    display: block;
    line-height: 1.9;
  }
}

.p-component {
  font-family: "Open Sans", sans-serif !important;
  font-size: .9rem!important;
}

.p-multiselect-item > .p-checkbox.p-component {
  margin-inline-end: 0.5rem !important;
}

.filter-overlay {
  max-width: 95%;
  // left: 10px !important;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
}

.stations-control {
  .p-slider-vertical {
    height: 150px !important; 
  }
}