@import "./themes";

.no-border {
  input,
  .p-dropdown,
  .p-multiselect {
    border: 0px;
    &:hover,
    &:focus {
      border: 0px !important;
    }
  }
}
.date-calendar-icon-position {
  position: absolute;
  inset-block-start: 34px;
  inset-inline-start: 10px;
  z-index: 1;
}

.time-calendar-icon-position {
  position: absolute;
  z-index: 1;
  inset-block-start: 35px;
  inset-inline-start: 53.4%;
}
.sxw-two-column-date-picker-theme {
  display: grid;
  grid-template-columns: 50% 49%;
  gap: 5px;

  .p-inputtext,
  .p-dropdown,
  .p-multiselect {
    border-radius: 4px !important;
    padding-inline-start: 20px;

    .p-dropdown-trigger {
      display: none;
    }
  }
  .p-calendar {
    input{
      padding-inline-start: 40px;
    }
  }

  .time-picker-container {
    position: relative;

    .time-picker-label {
      position: absolute;
      top: initial;
      left: initial;
      inset-block-start: -25px;
      inset-inline-end: 3px;
      color: #212121;
      font-weight: 600;
      font-size: 15px;
    }
  }
}

.input-theme {
  input,
  textarea,
  .p-dropdown,
  .p-multiselect {
    background-color: #f8f8f8 !important;
    border: 5pt !important;
    font-size: 15px !important;
    font-weight: normal;
    &.p-multiselect-chip .p-multiselect-token {
      color: inherit;
      background-color: #eff6ff;
      border-radius: 5pt;
    }
    &:hover,
    &:focus {
      border: 0px !important;
    }

    &:disabled,
    &.p-disabled,
    &[readonly] {
      background-color: #00000029 !important;
      color: #0a052b;
      opacity: 1;
    }
    &::placeholder {
      color: #a8a8a8;
    }
  }

  &.search-input-theme {
    input,
    input:hover,
    input:focus {
      border-radius: 20px;
      border: 1px #e9ebef solid !important;
      padding-inline-start: 20px;
    }
  }

  .switch-input {
    .switch-ball {
      height: 16px !important;
      width: 16px !important;
    }
    &.active {
      background-color: #0a052b !important;
      border: 1px solid #0a052b !important;
      .switch-ball {
        background-color: #fff !important;
        border: 1px solid #fff !important;
      }
    }
  }
}

.rtl-theme {
  direction: rtl;
}

.rtl-theme {
  .custom-theme {
    .p-input-icon-right > .p-icon-wrapper,
    .p-input-icon-right > i:last-of-type {
      right: unset;
      left: 10px !important;
    }
  }
}

.vogo-date {
  .p-calendar {
    input {
      padding-inline-start: 50px;
    }
  }
}
.calendar-icon-position {
  position: absolute;
  z-index: 1;
  inset-block-start: 10.4px;
  inset-inline-start: 15px;
  cursor: pointer;
}
.btn {
  &.red-background {
    button {
      background-color: #e54f6d;
      color: #fff;
      &:hover {
        background-color: #e54f6d !important;
        color: #fff !important;
      }
    }
  }
  &.red-text {
    button {
      color: #e54f6d;
    }
  }
  button {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20pt;
    font-size: 15px;
    background-color: #f2f4f9;
    padding-block-start: 10px;
    padding-block-end: 10px;
    color: #22162b;
    border-color: #f2f4f9;
    font-weight: 600;
    &:enabled:hover {
      background-color: #f2f4f9;
      border-color: #f2f4f9;
      color: #22162b;
    }
    sxw-svg-icon {
      margin-inline-end: 8px;
    }
  }
  &.fit-content {
    display: block !important;
    width: fit-content !important;
  }
  &.b-radius {
    button {
      border-radius: 14pt !important;
    }
  }
  &.primary {
    button {
      background-color: #0a052b;
      color: #fff;
      font-weight: 600;
      fill: #fff;

      &:enabled:hover {
        background: #110845;
        color: #ffffff;
        border-color: #110845;
      }

      sxw-svg-icon {
        margin-inline-end: 8px;
      }
      span {
        font-size: 16px;
      }
    }
  }

  &.otp-style {
    button {
      max-width: 172px;
      padding: 10px 0px;
      font-size: 14px;

      &:disabled {
        color: #a8a8a8;
      }
    }
  }

  &.no-icon-margin{
    button {
      sxw-svg-icon{
      margin-inline-end: 0px;
    } 
   } 
  }
  &.icon-only {
    button {
      &:enabled:hover,
      &:enabled:focus,
      &:enabled:active {
        background-color: transparent;
        border: 0;
        box-shadow: none;
      }
      background-color: transparent;
      border: 0;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.no-icon-margin{
    button {
      sxw-svg-icon{
      margin-inline-end: 0px;
    } 
   } 
  }

  &.pink {
    button {
      background-color: #e54f6d;
      color: #fff;
      font-weight: 600;
      fill: #fff;

      &:enabled:hover {
        background: #e54f6d;
        color: #ffffff;
        border-color: #e54f6d;
      }
    }
  }
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #22162b !important;
  border-color: #22162b !important;
}
a {
  text-decoration: none;
  color: #22162b;
}

a.back_btn,
a.p-button,
a.p-button:focus,
a.p-button:enabled:active,
a.p-button:enabled:hover {
  background: transparent;
  color: #22162b;
  font-weight: bold;
  border: 0px;
  box-shadow: none;
  justify-content: center;
  width: 100%;
}

.p-checkbox-label {
  margin-inline-start: 0.5rem;
}

.progress-loading {
  position: fixed !important;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 0.35rem !important;
}

.error {
  margin-block-end: 10px;
  &__msg {
    color: #b51313;
    font-weight: 500;
  }
}

.input-group {
  margin-block-end: 10px;
  position: relative;
  label {
    display: block;
    margin-block-end: 5px;
  }
}

.form-header {
  margin-block-end: 20px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0a052b;
}

.large {
  &.no-padding {
    .path {
      padding: 0px;
    }
  }
  h3 {
    font-size: 35px;
    text-transform: capitalize;
  }
}
// Filter Inputs Styles
.filter-body .p-inputtext,
.filter-body .p-dropdown-items {
  font-size: 15px !important;
  // padding: 8px 10px !important;
}

.filter-footer {
  button {
    background-color: #22162b !important;
    border: 1px solid #22162b !important;
    margin-right: 8px !important;
  }
}

.passenger-theme {
  .p-datatable .p-datatable-thead > tr > th {
    .header-cell {
      justify-content: flex-start !important;
    }

    .header-cell:last-child {
      justify-content: flex-end !important;
    }
  }
}
.three-dots {
  background-color: #0a052b;
  width: 50px;
  height: 40px;
  display: block;
  content: "";
  border-radius: 20px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 800;
}

.sxw-theme {
  .upload__action {
    .full-width {
      .image {
        .icon {
          top: 61%;
          right: 0;
        }
      }
    }
  }
}

.pac-container {
  z-index: 1150;
}

.p-component-overlay {
  z-index: 10;
}
.sidebar .p-sidebar {
  z-index: 20;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  margin-inline-end: 0.5rem !important;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 15px !important;
  padding-inline-end: 2.5rem !important;
}

sxw-list-cards > .container > .centered > .cards-container {
  align-self: auto !important;
  width: auto !important;
  .monitor-journey-card-accordion {
    .accordion-header {
      border: 0 !important;
      margin-bottom: 0 !important;
      .accordion-label {
        width: 100%;
      }
    }
  }
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border-bottom: initial !important;
  background-color: #f8f8f8 !important;
}

.p-accordion .p-accordion-content {
  padding-block-start: 0px;
  background-color: #f8f8f8 !important;
}
.no-padding {
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding: 0px;
  }
}

.p-image-mask {
  z-index: 10000 !important;
}

.form-container.no-overflow {
  overflow-x: initial;
}
.sxw-two-column-date-picker-theme .time-picker-container .time-picker-label {
  inset-block-start: -25px;
  inset-inline-start: 3px;
}

.route-slider {
  .p-slider-handle {
    background-size: contain !important;
    border: none !important;
    border-radius: 0 !important;
    width: 20px !important;
    height: 20px !important;
    left: 45% !important;
    background-repeat: no-repeat !important;
    &:nth-of-type(2) {
      background-image: url('/assets/images/icons/end_point.svg#end_point') !important;
    }
    &:nth-of-type(3) {
      background-image: url('/assets/images/icons/start_point.svg#start_point') !important;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
}