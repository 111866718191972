.input-theme {
  .readonly {
    .p-dropdown {
      background-color:#00000029;
      color: #0A052B;
      opacity: 1;

      .p-dropdown-trigger {
        display: none;
      }
    }
  }
}

.p-multiselect-filter-container {
  display: flex;
  order: 1;
}

.p-multiselect-close {
  order: 2;
}

.p-multiselect-token-icon {
  margin-inline-start: 5px !important;
}

.p-multiselect-label-container {
  padding-right: 0 !important;
  padding-inline-end: 1.75rem !important;
}