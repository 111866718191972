@font-face {
  font-family: 'Dg Sahabah';
  src: url('../fonts/sahabah/DG-Sahabah-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: Light;
  font-display: swap;
}

@font-face {
  font-family: 'Bahnschrift';
  src: url('../fonts/bahnschrift/bahnschrift.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  &[dir="rtl"] {
    font-family: 'Dg Sahabah', sans-serif !important;
    .p-component {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    .p-inputtext {
      font-family: 'Dg Sahabah', sans-serif !important;
      font-size: 15px !important;
    }
    input.search__input.ng-untouched.ng-pristine.ng-valid {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    button.footer__btn {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    button.map-btn {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
    textarea#float-input {
      font-family: 'Dg Sahabah', sans-serif !important;
    }
  }

  &:not([dir]) {
    font-family: 'Bahnschrift', sans-serif !important;
    .p-component {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    .p-inputtext {
      font-family: 'Bahnschrift', sans-serif !important;
      font-size: 15px !important;
    }
    input.search__input.ng-untouched.ng-pristine.ng-valid {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    button.footer__btn {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    button.map-btn {
      font-family: 'Bahnschrift', sans-serif !important;
    }
    textarea#float-input {
      font-family: 'Bahnschrift', sans-serif !important;
    }
  }
}

:root {
  --icons-color: #242c42;
  --inputs-color: #f6f6f9;

  --page-title-space-start: 60px;
  --form-layout-space-start: 14px;
  --page-title-arrow-width: 16px;
  --page-title-arrow-space-end: 0.5rem;

  --dropdown-icon: url('/assets/images/icons/Polygon 8.svg');
  --filter-input-icon: url('/assets/images/icons/magnifying-glass.svg');
}

* {
  box-sizing: border-box;
}

body {
  
  font-weight: 500;
  font-size: .9rem;
  margin: 0 auto;
  color: #22162B;
  background-color: #FAFBFC;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #9c9ea5;
  background-color: #9c9ea5;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background-color: #e2e2e2;
  border-radius: 30px;
}

.filters-container {
  width: 100%;
  height: 60px;
  position: relative;
  z-index: 20;
  border-top: 1px solid #EEF2F6;
  &.no-border{
    border-top: 0;
  }
  padding-top: 20px;
}

.filter-search {
  margin-left: 14px;
}

.steper-container {
  width: 16%;
}

sxw-form {
  flex: 1;
  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-height: 0;
  /* ......................................................................................... */
  & > .form-container {
    height: calc(100% - 30px);
  }

  .step-content {
    height: 100% !important;
    /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
    min-height: 0;
    /* ......................................................................................... */
    display: flex;
    flex-direction: column;

    .content-container {
      height: 100%;
    }
  }

  div.form-buttons {
    padding: 5px 10px !important;
  }
}

.form-container {
  flex: 1;
  padding-top: 4px;
  overflow-x: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.form-layout {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  gap: 22px;

  &:not(.form-tabs-layout .form-layout) {
    padding: 0 0 0 var(--form-layout-space-start);
  }
}

.form-tabs-layout {
  padding: 0 0 0 var(--form-layout-space-start);

  .form-layout {
    height: 89%;
  }
}

sxw-page-title:not(.not-aligned) .path {
  padding-inline-start: 10px !important;
  margin-top: 20px;
}

sxw-page-title.not-aligned .path {
  padding-inline-start: 0 !important;
}


.content {
  background-color: #ffffff;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-width: 0;
  /* ......................................................................................... */
}

.content .body-content {
  display: flex;
  flex-direction: column;
  //height: 100%;
  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-height: 0;
  /* ......................................................................................... */

  position: relative;
  padding: 0px 7%;
  height: 100%;
  @media screen and (max-width: 768px) {
    padding:  0 6%;
  }

  & > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.table-container {
  flex: 1;
  width: 100%;
  max-height: calc(100% - 275px);
  //max-height: calc(100% - 254px);
  height: auto;
  margin: 20px auto 0 4px;
  overflow-y: auto;

  &::-webkit-scrollbar:horizontal {
    height: 0px;
  }

  //&::-webkit-scrollbar:vertical {
  //  display: none;
  //}
}

.footer {
  position: relative;
  width: 100%;
}

.listing-footer {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  margin-block: 20px;
}

.listing-page-title {
  margin-inline-start: -20px;
}

.page-container {
  position: relative;
  height: 100%;
}

.p-datatable .p-datatable-wrapper {
  height: 100%;
  overflow: visible !important;
}
.status-active, .status-inactive, .status-pending, .status-accepted, .status-initiated, .status-initiatedExpired, .status-unpaidActive, .status-expired, .status-cancelled, .status-paused, .status-pastDue, .status-cancellationScheduledAt, .status-ongoing {
  padding: 3px 16px;
  border-radius: 20px;
  color: white;
  font-size: .8rem;
  text-transform: uppercase;
  background-color: #3361E5;
}
.status-inactive {
  background-color: #E54F6D;
}
.status-pending {
  background-color: #515151;
}
.status-accepted {
  background-color: #3abf68;
}

.status-initiated{
  background-color: #3498db;
}

.status-initiatedExpired{
  background-color: #e67e22;
}

.status-unpaidActive{
  background-color: #f1c40f;
}

.status-expired{
  background-color: black;
}
.status-pastDue {
  background-color: rgb(202, 131, 0);
}
.status-cancellationScheduledAt, .status-past {
  background-color: #95a5a6;
}
.status-cancelled {
  background-color: #E54F6D;
}

.status-paused {
  background-color: #9b59b6;
}
.view-information-section {
  .title {
    text-transform: uppercase;
    color: #a4abb2;
    font-weight: 100;
    border-block-end: 1px solid #eef2f6;
    margin-block: 30px 25px;
    padding-block-end: 10px;
  }

  p.label {
    color: #515151c4;
    font-weight: 200;

    span.value {
      color: #0A052B;
      padding-inline-start: 5px;
    }
  }
}

@media (max-width:426px) {
  .listing-page-title{
    margin-inline-start:0;
  }

}

sxw-form{
  div.form-buttons {
    button.b-reverse::before {
      content: "\e932" !important;
    }
    button.n-reverse::after {
      content: "\e931" !important;
      right: unset;
      left: 5px;
    }

    button {
      background-color: #41beb2;
      border: none;
      border-radius: 20px;
      margin-right: 20px;
      position: relative;
      height: 37px;
      width: 85px;
      &.submit-button:enabled:hover {
        background: #41BEB2;
      }
    }
  }
}


.rtl-theme{
.dropdown{
.p-dropdown-clear-icon{
  right: unset !important;
  left: 40px;
}
}
}

.rtl-theme{
.custom-dropdown-panel{
  .p-dropdown-header .p-dropdown-filter-icon{
     right: unset !important;
     left: 2px;
   }
 } 
}


.rtl-theme{
.custom-multi-dropdown{
.p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon{
right:unset !important;
left: 2px !important;
 }
}
}

.rtl-theme p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon{
  right: unset !important;
  left: 40px !important;
}

.rtl-theme sxw-mobile-number .code-direction{
left:unset !important;
right: 10px !important;
// unicode-bidi: bidi-override !important;
}


.rtl-theme .step:not(:last-child)::after{
  right: calc(50% + 10px) !important;
}

.rtl-theme {
  .journey-details {
    .pickup-station {
      &::after {
        transform: scaleX(-1) !important;
      }
    }

    .dropoff-station {
      align-items: flex-end;
      &::before {
        transform: scaleX(-1) !important;
      }
    }
  }
}

.custom-table-container {
  max-height: calc(100vh - 425px) !important;
  min-height: 350px;
}

.rtl-theme .details-icon img{
  transform: scaleX(-1); 
}

.mobileDesign{
  display: block;
  direction: ltr;
}

.p-tabview-panel {
  display: flex !important;
  flex-direction: column !important;
}