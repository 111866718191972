.search-dropdown-icon {
  & > div {
    position: relative;
    &::before {
      z-index: 10;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      inset-inline-start: 15px;
      transform: translateY(-50%);
   }
  }
  p-multiselect .p-multiselect-label {
    padding-inline-start: 3.2rem !important;
  }
  span.p-dropdown-label.p-inputtext {
    padding-inline-start: 3.2rem;
  }
}

.search-passengers {
  & > div {
    &::before {
      content: url('/assets/images/icons/businessman.svg#businessman');
    }
  }
}

.search-trip-type-icon {
  & > div {
    &::before {
      content: url('/assets/images/icons/switch.svg#businessman');
      transform: translateY(-50%) rotate(90deg);
    }
  }
}

.search-vogo-address-icon {
  position: relative;
  &::before {
    content: '';
    top: 50%;
    transform: translateY(-50%);
    inset-inline-start: 15px;
    position: absolute;
    z-index: 10;
  }
  &.blue::before {
    border: 6px solid blue;
    border-radius: 50%;
  }
  &.green::before {
    border: 6px solid #41BEB2;
    border-radius: 6px;
  }
  &::before {
    width: 10px;
    height: 10px;
    background-color: white;
  }
  .p-inputtext {
    padding-inline-start: 3.2rem;
  }
}