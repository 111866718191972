
.info-content {
  display: flex;
  justify-content: flex-start;
}

.info-title {
  font-size: 12px;
  padding-block: 10px;
  font-weight: 500;
  color: #A4ABB2;
  border-block-end: 1px solid #eee;
}

.info-label {
  margin: 0;
  margin-inline-end: 5px !important;
  color: #515151;
  font-size: 14px;
}
.info-value {
  margin: 0;
  color: #0A052B;
  font-size: 14px;
}

