@import "./kernel/";

.full-width-input,
.full-width-input input,
.full-width-input textarea,
.full-width-input select,
.full-width-input .p-inputnumber {
  width: 100%;
}

input.invalid-input,
select.invalid-input,
textarea.invalid-input,
.invalid-input input,
.invalid-input .p-dropdown,
.invalid-input .p-multiselect {
  border: 1px solid red !important;
}

.svg-icon > svg {
  width: 100%;
  height: 100%;
  display: block;
}

.invalid-input {
}

/* Pagination Styles */
.pagination__left {
  .sxw-theme {
    p-dropdown {
      box-shadow: 0 0 3px #c1c1c1;
      border-radius: 20px;
      padding: 1px;

      .p-dropdown-label {
        // padding: 0 15px;
        font-weight: bold;
      }

      .p-dropdown-trigger {
        display: none;
      }
    }
    .pagination-dropdown {
      max-height: fit-content;
      background-color: transparent;
      border: none;

      &:not(.p-disabled):hover {
        border: none !important;
      }

      .p-dropdown-items-wrapper {
        width: 4rem !important;
      }
    }
  }
}

.online-status {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  &--small {
    width: 12px;
    height: 12px;
  }
  &--ONLINE {
    background-color: #1ad67b;
    border: 1px solid #fff;
  }
  &--AWAY {
    background-color: #f8a600;
    border: 1px solid #fff;
  }
  &--OFFLINE {
    background-color: transparent;
  }
}

.sidebar {
  .popup-title {
    margin: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.sxw-card.sxw-theme {
  .card-title,
  .card-body {
    font-weight: bold;
    font-size: 13px;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .card-item {
    display: flex;
    flex: 1;
    align-items: center;
  }
  .card-item-key {
    width: 45%;
  }

  .card-item-value {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    &.tags {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .tag {
        width: max-content;
        padding: 5px;
        border-radius: 5px;
        background-color: #8ea6eb;
        color: white;
        font-size: 12px;
      }
    }
  }
}

.no-padding {
  .sidebar__content {
    padding: 0px !important;
  }
}


.p-dialog .footer{
  flex-direction: row-reverse !important;  
  justify-content: flex-start !important;   
  gap: 15px ; 
  margin-left: 20px
}
